<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">아웃바운드 대시보드</h1>
    <!-- 상단 조회폼 시작 -->
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="aspCustList"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="selected"
              :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]" no-title dark color="#F7AFAE" @change="startDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="dates[0]"
                @change="endDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
            </div>
          </v-menu>
          <v-text-field
            class="form-inp ml-2"
            name="name"
            label="아웃바운드명"
            id="id"
            outlined
            hide-details
            v-model="campaignName"
          ></v-text-field>
        </div>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" @click="selectObndCallList">조회</v-btn>
      </div>
    </div>
    <!-- 상단 조회폼 끝   -->

    <!-- 아웃바운드 리스트 시작-->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        아웃바운드리스트
        <div class="ml-auto">
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')"
            outlined
            class="btn-point"
            @click="downloadExcel('divGridObndCallList', '아웃바운드리스트')"
          >
            엑셀다운로드
          </v-btn>
        </div>
        <v-dialog
          max-width="1400"
          v-model="dialogM010101P01"
          :eager="true"
          content-class="square-modal"
        >
          <dialog-M010101P01
            headerTitle="아웃바운드 상세"
            @hide="hideDialog('M010101P01')"
            :gridDetailData="gridDetailData"
            @input="gridDetailData = $event"
            ref="dialog_M010101P01"
          ></dialog-M010101P01>
        </v-dialog>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="325px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          id="divGridObndCallList"
          item-key="CAM_ID"
          single-select
          :page.sync="page"
          hide-default-footer
          class="grid-default case03"
          @page-count="pageCount = $event"
          @dblclick:row="showModalInfo"
          @click:row="setDetailData"
          no-data-text="검색된 결과가 없습니다."
        ></v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
    <!-- 아웃바운드 리스트 끝  -->

    <!-- 아웃바운드 상세정보 시작-->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">아웃바운드상세정보</h2>
      <div class="table-form">
        <table>
          <caption class="hide">아웃바운드 상세정보 테이블</caption>
          <colgroup>
            <col width="10%" />
            <col width="40%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">아웃바운드명</th>
              <td>
                <v-text-field
                  class="form-inp full"
                  name="name"
                  :value="gridDetailData.CAM_NM"
                  id="id"
                  outlined
                  hide-details
                  disabled
                ></v-text-field>
              </td>
              <th scope="row">시작일자</th>
              <td>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal"
                  dark
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                      class="form-inp sm icon-calendar full"
                      append-icon="svg-calendar"
                      disabled
                      :value="gridDetailData.CAM_BEGIN_DATE"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    color="#F7AFAE"
                    no-title
                    @input="menu2 = false"
                    disabled
                    :first-day-of-week="1"
                    locale="ko-KR"
                    :day-format="mixin_getDate"
                  ></v-date-picker>
                </v-menu>
              </td>
              <th scope="row">종료일자</th>
              <td>
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal"
                  dark
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="gridDetailData.CAM_EOT_DATE"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                      class="form-inp sm icon-calendar full"
                      append-icon="svg-calendar"
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" color="#F7AFAE" no-title disabled
                    :first-day-of-week="1"
                    locale="ko-KR"
                    :day-format="mixin_getDate"
                  ></v-date-picker>
                </v-menu>
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="2">비고</th>
              <td rowspan="2">
                <v-textarea class="textarea-type0" label :value="gridDetailData.REM" no-resize outlined disabled></v-textarea>
              </td>
              <th scope="row">총건수</th>
              <td>
                <v-text-field
                  class="form-inp full"
                  reverse
                  name="name"
                  id="id"
                  outlined
                  hide-details
                  prefix="건"
                  :value="gridDetailData.TOTAL_CNT"
                  disabled
                ></v-text-field>
              </td>
              <th scope="row">배분건수</th>
              <td>
                <v-text-field
                  class="form-inp full"
                  reverse
                  name="name"
                  id="id"
                  outlined
                  hide-details
                  prefix="건"
                  :value="gridDetailData.DIV_CNT"
                  disabled
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">처리건수</th>
              <td>
                <v-text-field
                  class="form-inp full"
                  reverse
                  name="name"
                  id="id"
                  outlined
                  hide-details
                  prefix="건"
                  :value="gridDetailData.CAM_ID"
                  disabled
                ></v-text-field>
              </td>
              <th scope="row">미배분건수</th>
              <td>
                <v-text-field
                  class="form-inp full"
                  reverse
                  name="name"
                  id="id"
                  outlined
                  hide-details
                  prefix="건"
                  :value="gridDetailData.NOT_DIV_CNT"
                  disabled
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 아웃바운드 상세정보 끝  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogM010101P01 from "./M010101P01.vue";
import { mixin } from "@/mixin/mixin.js";
let jsonObj;
export default {
  name: "MENU_M010101", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogM010101P01,
  },
  created(){
console.log("here111111111111111111111111111");
  },
  data: () => ({
    dialogM010101P01: false,
    menu1: false,
    menu2: false,
    selected: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    campaignName: "",
    gridDataHeaders: [
      { text: "No", align: "center", value: "ROW_NUMBER", width: "50px", sortable: false },
      { text: "아웃바운드명", value: "CAM_NM", align: "center", width: "100px", sortable: false },
      { text: "총건수", value: "CNT_D", align: "center", width: "100px", sortable: false },
      { text: "배분건수", value: "DIV_CNT", align: "center", width: "100px", sortable: false },
      { text: "미배분건수", value: "NOT_DIV_CNT", align: "center", width: "100px", sortable: false },
      { text: "실행시작일자", value: "CAM_BEGIN_DATE", align: "center", width: "100px", sortable: false },
      { text: "실행종료일자", value: "CAM_EOT_DATE", align: "center", width: "100px", sortable: false },
      { text: "비고", value: "REM", align: "center", width: "100px", sortable: false },
      { text: "처리완료건수", value: "CAM_ID", align: "center", width: "100px", sortable: false },
      { text: "처리미완료건수", value: "CNT_Y", align: "center", width: "100px", sortable: false },
      { text: "처리불가건수", value: "CNT_N", align: "center", width: "100px", sortable: false },
      { text: "처리중건수", value: "CNT_D", align: "center", width: "100px", sortable: false },
      { text: "최종시도실패", value: "CNT_J", align: "center", width: "100px", sortable: false },
      { text: "기타(메시지발송)", value: "CNT_F", align: "center", width: "110px", sortable: false },
      { text: "처리완료(완판)", value: "CNT_G", align: "center", width: "100px", sortable: false },
      { text: "처리완료(불판)", value: "CNT_PF", align: "center", width: "100px", sortable: false },
      { text: "처리완료(10회시도)", value: "CNT_FA", align: "center", width: "130px", sortable: false },
      { text: "처리완료(안내장발송)", value: "CNT_TN", align: "center", width: "130px", sortable: false },
    ],
    gridDataText: [],
    gridDetailData: {},
    page: 1,
    pageCount: 0,
    itemsPerPage: 8,
    totalVisible: 10,
    selectedRow: [],

    RDOnly:false,
  }),
  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    setDetailData(value, row) {
      //클릭한 로우 활성화, v-data-table의 single-select 를 주고, item-key를 고유식별자로 해 놓으면 됨.
      if (value != null) {
        this.activeRow(row, true);
      }

      //상세 데이터를 세팅
      this.gridDetailData = value;
      this.gridDetailData.ASP_CUST_KEY = this.selected;
    },
    activeRow(row, value) {
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },
    clearDetailData() {
      this.gridDetailData = {};
    },
    showModalInfo(event, { item }) {
      this.showDialog("M010101P01");
    },
    showDialog(type) {
      //모달 그리드 내용 초기화
      this.$refs.dialog_M010101P01.gridDataText = [];
      this.$refs.dialog_M010101P01.FISH_TY = "";
      this.$refs.dialog_M010101P01.CSLT_ID = "";
      this.$refs.dialog_M010101P01.CSLT_NM = "";
      this.$refs.dialog_M010101P01.CSLT_NICK = "";
      this.$refs.dialog_M010101P01.CUST_ID = "";
      this.$refs.dialog_M010101P01.CUST_NM = "";
      //모달 다이얼로그 show
      this[`dialog${type}`] = true;

      //모달 로드시 자동 조회
      this.$refs.dialog_M010101P01.selectObndIngList();
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    async selectObndCallList() {
      if (this.selected == "" || this.selected == undefined) {
        this.common_alert("회사를 먼저 선택해주세요.", "error");
        return;
      }
      let requestData = {
        headers: {},
        sendData: {},
      }

      requestData.headers["URL"] = "/api/phone/dashboard/outbound/inqire";
      requestData.headers["SERVICE"] = "phone.dashboard.outbound";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["ROW_CNT"] = 50;
      requestData.headers["PAGES_CNT"] = 1;

      requestData.sendData["ASP_NEWCUST_KEY"] = this.selected;
      requestData.sendData["START_OBND_DATE"] = this.dates[0];
      requestData.sendData["END_OBND_DATE"] = this.dates[1];
      requestData.sendData["CAM_NM"] = this.campaignName;

      let response = await this.common_postCall(requestData, false);

      if (!response.HEADER.ERROR_FLAG) {
        this.clearDetailData();

        //선택된 로우가 존재한다면
        if (this.selectedRow != "") {
          this.activeRow(this.selectedRow, false);
          //초기화
          this.selectedRow = "";
        }

        this.setGridText(JSON.stringify(response.DATA));
      } else {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
        console.log(response.HEADER.ERROR_MSG);
      }
    },
    setGridText(jsonData) {
      jsonObj = JSON.parse(jsonData); //json string data를 object 형태로 파싱

      for (let key in jsonObj) {
        let startDate = this.dateFormat(jsonObj[key].CAM_BEGIN_DATE);
        let endDate = this.dateFormat(jsonObj[key].CAM_EOT_DATE);
        jsonObj[key].CAM_BEGIN_DATE = startDate;
        jsonObj[key].CAM_EOT_DATE = endDate;
      }

      this.gridDataText = jsonObj;
    },
    dateFormat(date) {
      //날짜 포멧
      if (!date) return "";
      var newDate = "";
      //공백제거
      date = date.replace(/\s/gi, "");

      if (date.length == 8) {
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        return newDate;
      } else {
        return date;
      }
    },
    downloadExcel(table_id, file_name) {
      //mixin에 셋팅된 method 호출
      this.table_id = table_id;
      this.file_name = file_name;
      this.mixin_common_exportExcel();
    },

    
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  mounted(){
    console.log('mm')

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.selected = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  }
};
</script>

<style>
</style>